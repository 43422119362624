import Image from 'next/image'
import { Logo } from '../common/Logo'
import React, { useEffect, useRef, useState } from 'react'
import { ComponentHeroFullWidth } from 'types/generated/contentful-types'
import { imageLoader } from '@/utils/ImageLoaders'
import { RotatingText } from '../design/RotatingText'
import IconDownArrow from '/public/images/icon-down-arrow.svg'
import IconDownArrowLight from '/public/images/icon-down-arrow-light.svg'
import { ButtonLink } from '../ui/ButtonLinks'
import { useReviewsContext } from 'context/ReviewsContext'
import { StarColors, Stars } from '../testimonial/Stars'
import Link from 'next/link'
import { formatAverage } from './ReviewsData'
import { CloudinaryAsset } from '../cloudinary/Types'

export type ComponentHeroFullWidthProps = Omit<
  ComponentHeroFullWidth,
  'contentfulMetadata' | 'sys' | '_id'
> & {
  fullHeroSubCopy?: string
  darkOverlay?: boolean
}

const FullWidthHero = ({
  title,
  fullHeroSubCopy,
  showForaLogo,
  rotatingText,
  image,
  mobileImage,
  bottomText,
  lightText = true,
  buttonCtaText,
  buttonCtaUrl,
  urlTarget,
  fallbackImage,
  showAverageRating,
  sectionId,
  darkOverlay,
  cloudinaryAsset,
  mobileCloudinaryAsset,
}: ComponentHeroFullWidthProps): JSX.Element => {
  const hasRotatingText = rotatingText && rotatingText?.length > 0

  const scollToRef = useRef<HTMLInputElement>(null)
  const handleBottomTextClick = () => {
    const globalBanner = document.querySelector('.global-banner')
    const boxHeight = scollToRef?.current?.offsetHeight
    const bannerHeight = globalBanner?.clientHeight || 0
    if (boxHeight) {
      window?.scrollTo({
        left: 0,
        top: boxHeight + bannerHeight,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div
      id={sectionId ?? undefined}
      ref={scollToRef}
      className="relative grid place-items-center place-content-center"
      data-name="full-width-hero-wrapper"
    >
      <style jsx>{`
        [data-name='full-width-hero-wrapper'] {
          min-height: min(100vh, 578px);
        }
        [data-name='full-width-hero'] {
          /* Three rows */
          grid-template-rows: ${hasRotatingText ? '.99fr' : '0.49fr'} auto 1fr;
        }

        @media screen and (min-width: 1024px) {
          [data-name='full-width-hero-wrapper'] {
            min-height: min(100vh, 760px);
          }
          [data-name='full-width-hero'] {
            /* Three rows */
            grid-template-rows: ${hasRotatingText ? '.93fr' : '0.81fr'} auto 1fr;
          }
        }
      `}</style>
      <section
        /* The hero is made up of three rows. The top and bottom rows have heights determined by the styled-jsx above. 
        Any content that is centered between the top and bottom offsets should be placed in the middle row. Specifically,
        the title belongs in the middle row, and this positions the title as it is in the designs. So you typically want 
        to add any other elements in the row above or the row below, so the title can stay centered. The average rating, 
        for example, sits right below the title, but shouldn't affect the position of title, so it gets placed in the 
        bottom row. The sub-copy, however, does offset the title because it gets centered along with the title as 
        one unit, so it gets placed in the middle row with the title. 
        This container should always have exactly three children. */
        className="w-screen grid min-h-[inherit]"
        data-name="full-width-hero"
      >
        {/* First Row */}
        <span className="z-10 flex flex-col">
          {showForaLogo && (
            <div className="mt-6 ml-4 lg:ml-12 lg:mt-8" data-name="brand-logo">
              <Logo
                width={90}
                height={28}
                color={lightText ? 'white' : 'black'}
                loadWithPriority={true}
              />
            </div>
          )}
        </span>

        {/* Second Row */}
        <span className="z-10 block">
          <div
            className={`flex flex-col items-center justify-center text-center w-full h-[max-content] px-5 lg:px-40 ${
              lightText && 'text-sand'
            } fora-text-h1`}
            data-name="heading"
          >
            <h1>{title}</h1>
            {!hasRotatingText && fullHeroSubCopy && (
              <p className="font-serif text-base md:text-xl tracking-normal mx-auto mt-[36px] md:mt-[46px] px-9 md:max-w-[423px]">
                {fullHeroSubCopy}
              </p>
            )}
            {hasRotatingText && (
              <RotatingText rotatingTextContent={rotatingText || []} />
            )}
          </div>
        </span>

        {/* Third Row */}
        <span className="z-10 flex flex-col">
          <div /* Top of Third Row */ className="mb-5">
            {showAverageRating && (
              <AverageRating
                lightText={lightText}
                showAverageRating={showAverageRating}
              />
            )}
          </div>
          <div /* Bottom of Third Row */ className="mt-auto">
            {buttonCtaText && buttonCtaUrl && (
              <div className="flex justify-center cursor-pointer mb-11">
                <ButtonLink
                  href={buttonCtaUrl ?? ''}
                  target={
                    (urlTarget as '_self' | '_blank' | undefined) || '_self'
                  }
                  text={buttonCtaText ?? ''}
                  theme={lightText ? 'transparentLight' : 'primary'}
                />
              </div>
            )}
            {bottomText && (
              <button
                className={`flex flex-col items-center justify-center gap-4 w-full mb-4 md:mb-[26px] cursor-pointer ${
                  lightText && ' text-sand '
                } fora-text-subtitle-2`}
                data-name="subcopy"
                onClick={() => handleBottomTextClick()}
              >
                <p className="uppercase fora-text-eyebrow-1">{bottomText}</p>
                <Image
                  loader={({ src }) =>
                    imageLoader({
                      src: src,
                      width: 10,
                      quality: 90,
                    })
                  }
                  src={lightText ? IconDownArrowLight : IconDownArrow}
                  width={35}
                  height={35}
                  alt="downward pointing arrow"
                />
              </button>
            )}
          </div>
        </span>
      </section>

      {/* Background Asset */}
      <BackgroundAsset
        mobileImage={mobileImage}
        image={image}
        fallbackImage={fallbackImage}
        darkOverlay={darkOverlay}
        cloudinaryAsset={cloudinaryAsset}
        mobileCloudinaryAsset={mobileCloudinaryAsset}
      />
    </div>
  )
}

/**
 * The BackgroundAsset component uses CSS media queries to control the visibility of the mobile and desktop assets.
 * While using the `isMobile`/`useBreakpoint` helper functions would be more performant, the initial value returned from
 * the `createBreakpoint` package is always a mobile screen size causing the mobile asset to render initially. So on
 * desktop screens, this results in a visible switch from the mobile asset to the desktop one during page load. With
 * media queries, the correct asset is rendered immediately, eliminating the switch.
 *
 * Note: The `isMobile` helper function refers to 0px - 1024px screens, but for this component, mobile is considered
 * 0px - 768px.
 *
 * @returns Mobile and desktop assets controlled by CSS media queries
 */

type BackgroundAssetProps = Pick<
  ComponentHeroFullWidth,
  | 'mobileImage'
  | 'image'
  | 'fallbackImage'
  | 'cloudinaryAsset'
  | 'mobileCloudinaryAsset'
> & {
  darkOverlay?: boolean
}

const BackgroundAsset = ({
  mobileImage,
  image,
  fallbackImage,
  darkOverlay,
  cloudinaryAsset,
  mobileCloudinaryAsset,
}: BackgroundAssetProps) => {
  const [src, setSrc] = useState(image)
  const [cloudinarySrc, setCloudinarySrc] = useState(cloudinaryAsset)
  const displayMobileImage = mobileImage ?? image
  const displayCloudinaryMobileImage = mobileCloudinaryAsset ?? cloudinaryAsset

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth
      const breakpoint = 768

      if (screenWidth < breakpoint) {
        setSrc(displayMobileImage)
        setCloudinarySrc(displayCloudinaryMobileImage)
      } else {
        setSrc(image)
        setCloudinarySrc(cloudinaryAsset)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [image, displayMobileImage, displayCloudinaryMobileImage, cloudinaryAsset])

  if (
    (!src || !src.url) &&
    (!cloudinarySrc[0]?.secure_url || !cloudinarySrc[0]?.url)
  ) {
    return <></>
  } else {
    if (
      (src?.contentType &&
        (src.contentType.includes('video') || src.url?.includes('mp4'))) ||
      (cloudinarySrc?.resourceType && cloudinarySrc?.resourceType === 'video')
    ) {
      return (
        <VideoAsset
          image={(cloudinarySrc && cloudinarySrc[0]) || src}
          fallbackImage={fallbackImage}
          className="block"
          darkOverlay={darkOverlay}
        />
      )
    } else {
      return (
        <div
          className={`absolute h-full w-screen overflow-hidden ${
            darkOverlay ? 'dark-overlay' : ''
          } block`}
          data-name="full-width-image"
        >
          <div className="full-width-hero-mobile">
            {(mobileCloudinaryAsset || image || mobileImage) && (
              <Image
                src={
                  mobileCloudinaryAsset?.[0].secure_url ??
                  mobileImage?.url ??
                  image?.url
                }
                data-src={
                  mobileCloudinaryAsset?.[0].secure_url ??
                  mobileImage?.url ??
                  image?.url
                }
                alt={image?.description ?? ''}
                fill
                objectFit="cover"
                objectPosition="center"
                quality={100}
              />
            )}
          </div>
          <div className="full-width-hero">
            {(cloudinaryAsset || image) && (
              <Image
                src={cloudinaryAsset?.[0].secure_url ?? image?.url}
                data-src={cloudinaryAsset?.[0].secure_url ?? image?.url}
                alt={image?.description ?? ''}
                fill
                objectFit="cover"
                objectPosition="center"
                quality={100}
              />
            )}
          </div>
        </div>
      )
    }
  }
}

type VideoAssetProps = Pick<ComponentHeroFullWidth, 'fallbackImage'> & {
  image: ComponentHeroFullWidth['image'] | CloudinaryAsset
  className?: string
  darkOverlay?: boolean
}

const VideoAsset = ({
  image,
  fallbackImage,
  className,
  darkOverlay,
}: VideoAssetProps) => {
  return (
    <div
      className={`absolute h-full w-screen overflow-hidden !z-0 ${
        darkOverlay ? 'dark-overlay' : ''
      } ${className}`}
      data-name="full-width-video"
    >
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline
        controls={false}
        poster={fallbackImage?.url || ''}
        className="absolute w-[inherit] h-[inherit] inset-x-0 object-cover object-center -translate-y-1/2 top-1/2"
      >
        {image && image?.url && (
          <source src={(image as CloudinaryAsset).secure_url || image.url} />
        )}
      </video>
    </div>
  )
}

type AverageRatingProps = Pick<
  ComponentHeroFullWidth,
  'lightText' | 'showAverageRating'
>
const AverageRating = ({
  lightText,
  showAverageRating,
}: AverageRatingProps): JSX.Element => {
  const { sectionId, averageRating, totalCount } = useReviewsContext()

  if (!showAverageRating || !sectionId || !averageRating || !totalCount)
    return <></>

  return (
    <div className="flex flex-col mt-8" data-name="average-rating">
      <div className="flex items-center justify-center w-full max-w-full gap-x-2 whitespace-nowrap">
        <Stars
          rating={averageRating}
          aria-hidden
          width={20}
          gap={8}
          color={lightText ? StarColors.SAND : StarColors.BLACK_SAND}
          showEmptyStars
        />
        <p
          aria-label="Average rating"
          className={`fora-text-h6 tracking-btight ${lightText && 'text-sand'}`}
        >
          {formatAverage(averageRating)}
        </p>
      </div>
      <Link href={`#${sectionId}`} scroll legacyBehavior>
        <a
          className={`fora-text-navigation-1 underline text-center ${
            lightText && 'text-sand'
          }`}
          style={{
            textUnderlineOffset: '2px',
            textTransform: 'none',
            letterSpacing: '-0.01em',
          }}
        >
          See all {totalCount} reviews
        </a>
      </Link>
    </div>
  )
}

export default FullWidthHero
